<template>
  <div>

    <p class="cardTitle">UP 产品服务</p>
    <p class="cardSecondTitle">信息聚合、财税解决方案+灵活用工平台定制化</p>
    <div class="prodcutDiv">
      <img src="/img/serviceBg.jpg" class="prodcutImg" alt="产品服务"/>
      <ul class="serviceUl">
        <li>
          <div
            class="serviceCard nth1"
            @mousemove="serverceindex = 1"
            @mouseout="serverceindex = 0"
            :class="serverceindex == 1 ? 'hoverClass' : ''"
          >
            <i class="iconfont">&#xe7e2;</i>
            <p class="serviceTitle">财税解决方案</p>
            <p class="s1Title">佣金发放+个人申报一体化，讯捷服务</p>
            <p class="sTitle">多家银行战略合作，资金安全无风险</p>
          </div>
        </li>
        <li>
          <div
            class="serviceCard nth2"
            @mousemove="serverceindex = 2"
            @mouseout="serverceindex = 0"
            :class="serverceindex == 2 ? 'hoverClass' : ''"
          >
            <i class="iconfont">&#xe68b;</i>
            <p class="serviceTitle">saas系统</p>
            <p class="s1Title">针对各行业的特性，实现业务场景深度定制</p>
            <p class="sTitle">通过自研saas，定制化用工解决方案</p>
          </div>
        </li>
        <li>
          <div
            class="serviceCard nth3"
            @mousemove="serverceindex = 3"
            @mouseout="serverceindex = 0"
            :class="serverceindex == 3 ? 'hoverClass' : ''"
          >
            <i class="iconfont">&#xe62b;</i>
            <p class="serviceTitle">开放API接口</p>
            <p class="sTitle">提供对外API接口，实现将功能融入自身平台系统</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="industryDiv">
    <p class="cardTitle" style="margin-top: 80px;padding-top: 26px;">适用行业</p>
    <p class="cardSecondTitle">
      针对各行业业务特性，打造个性化行业解决方案，为您提供一站式的产品与服务
    </p>
    <ul class="industryUl">
      <li v-for="item in industryArr" :key="item.index"  :style="
            industryindex == item.index
              ? 'background-image:url(' + item.imgl + ');width:37.5%'
              : 'background-image:url(' + item.img + ');width:25%'
          "
          @mousemove="industryindex = item.index">
        <div
          class="industryCard"
        >
          <p
            class="industryTitle1"
            :class="industryindex == item.index ? 'mcCard' : 'mc'"
          >
            {{ industryindex == item.index ? item.title2 : item.title1 }}
          </p>
        </div>
      </li>
    </ul>
      <ul class="mobileIndustryUl">
       <li v-for="item in industryArr" :key="item.index"  :style="'background-image:url(' + item.img + ');width:25%'">
        <div class="industryCard" >
          <p class="industryTitle1 mc">
            {{  item.title1 }}
          </p>
        </div>
      </li>
    </ul>
    </div>
    <p class="cardTitle">平台优势</p>
    <div class="goodness">
      <div class="goodnessCard">
        <i class="iconfont">&#xe665;</i>
        <p class="goodnessTitle">经验背书</p>
        <p class="goodnessSecondTitle">
          UP平台已服务超500家企业，具备各行业服务经验
        </p>
      </div>
      <div class="goodnessCard">
        <i class="iconfont">&#xe932;</i>
        <p class="goodnessTitle">服务便捷</p>
        <p class="goodnessSecondTitle">
          提供免费实名认证、免邮寄费等服务用工人员小程序操作，方便快捷
        </p>
      </div>
      <div class="goodnessCard">
        <i class="iconfont">&#xe690;</i>
        <p class="goodnessTitle">技术赋能</p>
        <p class="goodnessSecondTitle">
          SaaS平台+API接口对接，提供线上化、智能化服务
        </p>
      </div>
      <div class="goodnessCard">
        <i class="iconfont">&#xe709;</i>
        <p class="goodnessTitle">资金安全</p>
        <p class="goodnessSecondTitle">
          税局授权委托代征资质，资金秒进秒出不停留
        </p>
      </div>
      <img class="goodnessImg" src="/img/goodBg.png" alt="平台优势"/>
    </div>
    <div class="ct6 ct" style="background-color:#fff">
    <div class="container">
        <div class="text-title">
            <div class="cardTitle">合作伙伴</div>
            <div class="cardSecondTitle">COOPERATIVE  PARTNER</div>
        </div>
        <div class="ct6_content">
            <div class="ct6_item"><img src="/img/partner1.png" alt="oppo"></div>
            <div class="ct6_item"><img src="/img/partner2.png" alt="vivo"></div>
            <div class="ct6_item"><img src="/img/partner3.png" alt="iQOO"></div>
            <div class="ct6_item"><img src="/img/partner4.png" alt="oneplus"></div>
            <div class="ct6_item"><img src="/img/partner5.png" alt="小天才"></div>
            <div class="ct6_item"><img src="/img/partner6.png" alt="极兔速递"></div>
            <div class="ct6_item"><img src="/img/partner7.png" alt="欢太"></div>
            <div class="ct6_item"><img src="/img/partner8.png" alt="realme"></div>
            <div class="ct6_item"><img src="/img/partner9.png" alt="尚游游戏"></div>
            <div class="ct6_item"><img src="/img/partner10.png" alt="当换"></div>
        </div>
        <div style="clear: both"></div>
    </div>
</div>
  </div>
</template>

<script>
export default {
  name: "home",
  props: {
    msg: String,
  },
  data() {
    return {
      serverceindex: 0,
      industryindex: 1,
      industryWidth: "240px",
      industryArr: [
        {
          img: "/img/industry01_s.png",
          imgl: "/img/industry01.png",
          title1: "批发零售",
          title2: "代理商、推广服务等",
          index: 1,
        },
        {
          img: "/img/industry02_s.png",
          imgl: "/img/industry02.png",
          title1: "电商平台",
          title2: "供应链、直播等",
          index: 2,
        },
        {
          img: "/img/industry03_s.png",
          imgl: "/img/industry03.png",
          title1: "物流配送",
          title2: "司机、骑手、装卸服务等",
          index: 3,
        },
        {
          img: "/img/industry04_s.png",
          imgl: "/img/industry04.png",
          title1: "软件开发",
          title2: "技术工程师、信息技术服务等",
          index: 4,
        },
      ],
    };
  },
  methods: {
    // industryOver(item) {
    //   item.img = item.imgl;
    //   this.industryWidth = "460px";
    // },
  },
};
</script>

<style scoped>

.prodcutDiv {
  width: 900px;
  margin: 0 auto;
  position: relative;
}
.prodcutImg {
  width: 900px;
  height: auto;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50px;
}
.atention {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 35px;
  left: 150px;
}



.serviceCard {
  background: #3EB2FF;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 350px;
  margin-bottom: 40px;
  border-radius: 25px;
  border: 1px solid #333;
  /* justify-content: center;  */
  border: none;
  color: #333;
  padding: 40px 20px;
  box-sizing: border-box;
}
.serviceCard i {
  font-size: 53px;
}
.serviceCard .serviceTitle {
  font-size: 26px;
  height: 70px;
  line-height: 70px;
}
.serviceCard .sTitle {
  height: 45px;
  line-height: 32px;
  font-size: 17px;
  text-align: center;
}

.serviceCard .s1Title{
  height: 45px;
  line-height: 32px;
  font-size: 17px;
  text-align: center;
 margin-bottom: 20px;
}
.hoverClass {
  background: #3EB2FF;
  border: 3px solid #fff;
  color: #fff;
}
.industryDiv{
  width: 100%;
    background: #f5f5f5;
}
.industryUl {
  display: flex;
  margin: 30px auto;
}
.industryUl li{
  background-size: 100% 100%;
}
.industryCard {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.mc {
  width: 100%;
  height: 100%;
  writing-mode: tb;
  background-color: rgba(0, 24, 71, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: middle;
  line-height: 240px;
  text-align: center;
  z-index: 99;
}
.mcCard {
  width: 210px;
  height: 210px;
  background-color: rgba(0, 24, 71, 0.5);
  border-radius: 50%;
  text-align: center;
  line-height: 210px;
}
.industryTitle1 {
  display: inline-block;
  color: #fff;
  font-size: 20px;
}
.goodness {
  margin: 55px auto;
  position: relative;
  height: 500px;
}
.goodnessCard {
  position: absolute;
  width: 220px;
  height: 170px;
  text-align: center;
}
.goodnessCard:first-child {
  left: 0;
  top: 0;
}
.goodnessCard:nth-child(2) {
  right: 0;
  top: 0;
}
.goodnessCard:nth-child(3) {
  left: 0;
  bottom: 0;
}
.goodnessCard:nth-child(4) {
  right: 0;
  bottom: 0;
}
.goodnessCard i {
  font-size: 35px;
  color: #3EB2FF;
}
.goodnessImg {
  border-radius: 23px;
  position: absolute;
  left: 0;
  right: 0;
  top: 137px;
  margin: auto;
  width: 400px;
  animation: fadenum 5s infinite;
}
@keyframes fadenum {
  50% {
    transform: translateY(15px);
  }
}
.goodnessCard .goodnessSecondTitle {
  color: #494b4c;
  margin-top: 10px;
  letter-spacing: 2px;
  font-size: 16px;
}
.goodnessTitle {
  color: #3EB2FF;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 700;
  margin-top: 25px;
}
.ct6 {
    padding-bottom: 50px;
}
.ct {
    background-color: #f4f8ff;
    width: 100%;
    position: relative;
}
.container {
    margin: 0 auto;
}
.text-title {
    text-align: center;
    margin-top: 66px;
}
.text-title .title {
    color: #326dfa;
    font-size: 35px;
    letter-spacing: 3px;
}
.text-title .ywtext {
    color: #b5bbc5;
    margin-top: 10px;
    font-size: 26px;
    letter-spacing: 1px;
}
.ct6 .ct6_content {
    margin-top: 30px;
}
.ct6 .ct6_item {
  height: 52px;
    width: 20%;
    float: left;
    padding: 40px 0;
    text-align: center;
    transition: all .4s 0s ease;
}
a {
    color: #333;
    text-decoration: none;
}
.ct6 .ct6_item img {
    width: 70%;
}

/* 笔记本 电脑  */
@media (min-width: 1031px) {
  .serviceUl {
  list-style: none;
  box-sizing: border-box;
  position: relative;
  height: 1000px;
}
.serviceUl:after {
  content: "";
  display: block;
  clear: both;
}
.serviceUl li:first-child {
  top: 55px;
  left: calc(50% - 150px);
  position: absolute;
}
.serviceUl li:nth-child(2) {
  bottom: 20px;
  left: -75px;
  position: absolute;
}
.serviceUl li:last-child {
  bottom: 20px;
  right: -75px;
  position: absolute;
}
.industryUl {
  width: 1100px;
}
 .mobileIndustryUl{
    display: none;
  }
  .mobileIndustryUl li{
  background-size: 100% 100%;
}
.goodness {
  width: 1100px;
}
.container {
    width: 1170px;
}



}

/*  pad pro 1024(pc一样)*/
@media (min-width: 770px) and (max-width: 1030px) {
  .industryUl{
     width: 86% ;
  }
  .mobileIndustryUl{
    display: none;
  }
  .goodness{
     width: 86% ;
  } 
  .container{
    width: 86% ;
  }
  .prodcutImg ,.prodcutDiv{
    width: 750px;
  }
  .serviceUl{
    height: 850px;
  list-style: none;
  box-sizing: border-box;
  position: relative;
  height: 1000px;
  }
 
.serviceUl:after {
  content: "";
  display: block;
  clear: both;
}
.serviceUl li:first-child {
  top: 55px;
  left: calc(50% - 150px);
  position: absolute;
}
.serviceUl li:nth-child(2) {
  bottom: 20px;
  left: -75px;
  position: absolute;
}
.serviceUl li:last-child {
  bottom: 20px;
  right: -75px;
  position: absolute;
}
  .serviceCard{
    width: 270px;
    height: 300px;
    padding: 20px 20px;
  }
  .serviceCard i{
    font-size: 48px;
}
.serviceCard .serviceTitle{
    font-size: 22px;
    height: 70px;
    line-height: 70px;
}
.serviceCard .s1Title{
    height: 45px;
    line-height: 32px;
    font-size: 16px;
}
.serviceUl li:nth-child(2) ,  .serviceUl li:last-child {
    bottom: 10px;
}
.goodnessImg{
  width: 280px;
}
.goodness {
  width: 90%;
}
.container {
    width: 90%;
}



}

/* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) {
  .prodcutDiv{
    width: 100%;
    margin-top: 20px;
  }
  .prodcutImg{
display: none;
  }
 .industryUl {
  width: 100%;
  display: none;
}
 .mobileIndustryUl{
    display: flex;
  }
.goodness {
  width: 100%;
}
.container {
    width: 100%;
}
.serviceCard{
     background: #3EB2FF;
    width: 85%;
    margin: 10px auto;
    height: auto;
    margin-bottom: 20px;
    border-radius: 25px;
    border: 1px solid #333;
    /* justify-content: center; */
    border: none;
    color: #fff;
    padding: 20px 20px;
    box-sizing: border-box; 
}
 .serviceCard i{
    font-size: 40px;
}
 
.serviceCard .serviceTitle {
    font-size: 20px;
    height: 45px;
    line-height: 45px;
}
.serviceCard .s1Title{
  line-height: 30px;
    font-size: 15px;
    height: auto;
    text-align: center;
    margin-bottom: 0;
}
.serviceCard .sTitle{
 line-height: 30px;
    font-size: 15px;
    text-align: center;
    height: auto;
}

.mcCard{
      width: 80%;
    height: 80%;
    border-radius: 10px;
}
.industryTitle1{
  line-height: 25vw;
}
.goodness{
  width: 100%;
    margin: 30px auto;
    height: auto;
}
.goodnessCard{
       position: unset;
    width: 50%;
    box-sizing: border-box;
    padding: 10px 20px;
    height: 220px;
    display: inline-block;
}
.goodnessImg{
  display: none;
}
.goodnessCard .goodnessSecondTitle{
    margin-top: 15px;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 24px;
}
.text-title{
  margin-top: 10px;
}
.ct6 .ct6_item {
  height: 32px;
      padding: 20px 0;
}
.ct6 .ct6_content {
    margin-top: 20px;
}


}
</style>
